.contained-image {
  @apply z-10;
  @apply backdrop-filter;
  @apply backdrop-blur;
}

.background-image {
  @apply z-0;
}

.image {
  @apply object-cover;
  @apply object-center;
}

.default-image {
  @apply object-cover;
  @apply object-center;
}

.wrapper {
  --header-size: 96px;
  max-height: calc(100vh - var(--header-size) - 50px);
  width: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
}

@media (min-width: 1280px) { 
  .wrapper {
    --header-size: 64px;
  }
}
.menuIconContainer {
  width: 50px;
  height: 50px;
}

.btnLine {
  width: 18px;
  height: 2px;
  margin-bottom: 5px;
}

.closedLine:nth-child(2) {
  display: none;
}

.closedLine:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.closedLine:nth-child(3) {
  transform: rotate(135deg);
}

.upperBlock {
  position: relative;
}

.upperBlock:after {
  content: '';
  position: absolute;
  bottom: -1.25rem;
  left: -1.25rem;
  height: 2px;
  width: calc(100% + 2.5rem);
  background: linear-gradient(to left, #8746ff, #1acdf4);
  box-shadow:
    0 4px 6px -1px rgba(255, 255, 255, 0.1),
    0 2px 4px -1px rgba(255, 255, 255, 0.06);
}

@media screen and (min-width: 1280px) {
  .upperBlock:after {
    display: none;
  }
}
